<template>
    <div class="itembox">
        <div class="header">
            <p>
                <img style="width:72px;height:72px;border-radius: 36px;" :src="membermsg.avatar?membermsg.avatar:default_header_img" alt="">
            </p>
            <div>
                <h1 class="textOverflow">{{membermsg.name_en | priorFormat(membermsg.name_zh,LOCALE)}}  </h1>
                <p class="textOverflow">{{ membermsg.job_title_en | priorFormat(membermsg.job_title_zh,LOCALE) }}</p>
            </div>
        </div>
        <div class="content">
            <p @click="to_companyDetai" style="cursor:pointer">
                <img :src="company_img" alt="">
                <span class="textOverflow" :title="membermsg.company_name_en | priorFormat(membermsg.company_name_zh,LOCALE)" >{{ membermsg.company_name_en | priorFormat(membermsg.company_name_zh,LOCALE) }}</span>
            </p>
            <p>
                <img :src="email_img" alt="">
                <span class="textOverflow" v-if="IS_LOGIN">{{ membermsg.email | textFormat }}</span>
                <span class="to_login" v-else type='text' @click="to_login">请登录</span>
            </p>
            <p>
                <img :src="tel_img" alt="">
                <span class="textOverflow" v-if="IS_LOGIN">{{membermsg.mobile | phoneFormat(membermsg.area_code) }}</span>
                <span class="to_login" v-else type='text'  @click="to_login">请登录</span>
            </p>
        </div>
        <!-- <div class="btn">
            <p class="btn_button" @click="handclick">
                <img :src="watch" alt="">
                <span>预约沟通</span>
            </p>
        </div> -->

        <orderFreeDialog :orderVisible="orderVisible" @dataChange='_dataChange' @orderVisibleShow="_orderVisibleShow" ></orderFreeDialog>

  </div>
</template>
<script>
import orderFreeDialog from '@/baseComponents/orderFreeDialog'
import {validateEmail} from "~/baseUtils/validate";
export default {
  props: {
    membermsg: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  components: {
    orderFreeDialog,
  },
  data() {
    return {
      default_header_img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/headportrait.png",
      company_img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/mpa/company_icom.jpg",
      email_img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/mpa/email_icon.jpg",
      tel_img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/mpa/phone_icon.jpg",
      watch: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/watch.png",
      orderVisible: false,
    };
  },
  methods: {
    validateEmail,
    handclick() {
      if(!this.IS_LOGIN){
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
      }else if(this.USER_INFO.company_bind_status==0 || !this.USER_INFO.company_id){  //没有绑定公司
          this.$message.warning(this.$t('company_information'))
      }else{
        this.orderVisible = true;
      }
      
    },
    to_companyDetai(){
      let routeUrl = this.$router.resolve({
        path: "/companyDetail",
        query:{
          parameter:this._encode({
            company_id:this.membermsg.company_id
          })
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    async _dataChange(data) {
      
      let {start_time,end_time,day,send_postscript}=data
      let params = {
        send_user_id: this.USER_ID, //
        send_company_id: this.USER_INFO.company_id,  //
        in_user_id: this.membermsg.user_id,
        in_company_id: this.membermsg.company_id,
        daily_source: 2,
        related_id: this.USER_INFO.company_id,  //
        start_time,
        end_time,
        day,
        send_postscript,
      };
      
      let res = await this.$store.dispatch("API_company/companyInvite", params);
      
      this.orderVisible = false
      if (res.success) {
        this.$message.success(this.$t("sendSuccess"));
        
      } else if (res.errorcode == 1000) {
        this.$message.warning(this.$t("repeatTheBooking"))
      } else if (res.errorcode == 1017) {
        this.$message.warning(this.$t("companyCatalog1v1Error2"))
      } else if (res.errorcode == 1018) {
        this.$message.warning(this.$t("companyCatalog1v1Error3"))
      }else{
        
      }

    },
    _orderVisibleShow(data) {
      this.orderVisible = false;
    },
    to_login(){
      this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
    }
  },
};
</script>
<style scoped lang="less">
.itembox {
  margin-bottom: 16px;
  margin-right: 16px;
  display: inline-block;
  box-sizing: border-box;
  display: inline-block;
  width: 288px;
  height: 260px;
  background: #ffffff;
  .header {
    display: flex;
    padding: 24px;
    p {
      width: 72px;
      height: 72px;
      margin-right: 19px;
    }
    div {
      h1 {
        width: 135px;
        overflow: hidden;
        height: 28px;
        font-size: 20px;
        font-weight: 800;
        color: #333333;
        line-height: 28px;
        margin-top: 12px;
      }
      p {
        margin-top: 5px;
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #7d8695;
        line-height: 22px;
        width: 135px;
        overflow: hidden;
      }
    }
  }
  .content {
    margin: 0 26px;
    p {
      padding: 6.5px 0;
      display: flex;
      img {
        width: 16px;
        height: 16px;
        margin-right: 14px;
      }
      span {
        display: block;
        height: 20px;
        width: 200px;
        overflow: hidden;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
      }
      .to_login{
        cursor: pointer;
        color: #0083F6
      }
    }
  }
  .btn {
    padding: 24px;
    .btn_button {
      cursor: pointer;
      width: 115px;
      height: 30px;
      background: linear-gradient(90deg, #ff9966 0%, #ff5e62 100%);
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 6px;
      }
      span {
        display: inline-block;
        width: 56px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
      }
    }
  }
}
</style>